import Slider from "react-slick";
// import Button from "../../common/Button/Button";
import Banner from "../../assets/images/png/Banner.png";
// import CardImg from "../../assets/images/png/card_img.png";
import newsimage1 from "../../assets/images/png/news_image1.png";
//import newsimage2 from "../../assets/images/png/news_image2.png";
import event1 from "../../assets/images/png/events.png";
// import event2 from "../../assets/images/png/image_4.png";
// import event3 from "../../assets/images/png/image_3.png";
// import newsimage2 from "../../assets/images/png/news_image2.png";
import clock from "../../assets/images/Icons/clock.png";
import SamplePrevArrow from "../../assets/images/Icons/Left_angle.png";
import SampleNextArrow from "../../assets/images/Icons/Right_angle.png";
import cycleday from "../../assets/images/png/CycleDay1.jpg"
import "./News.scss";

import logo from "../../assets/images/png/GUMILLlogo.png";

const News = () => {
  var teamSlider = {
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: (
      <div>
        {" "}
        <img src={SampleNextArrow} alt="banner-img" />{" "}
      </div>
    ),
    prevArrow: (
      <div>
        {" "}
        <img src={SamplePrevArrow} alt="banner-img" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 771,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="Events">
      <section>
        <div className="banner-image">
          <img src={Banner} alt="banner-img" />
        </div>
      </section>
      {/* /news/ */}
      <section className="card-container bg-darkGrey">
        <div className="news large-container slick-cascade">
          <h2>News</h2>
          <div className="news-details">
            <div className="news-images">
              <div>
                <img src={newsimage1} alt="banner-img" />
              </div>
              <div>
                <h3>
                  Bosch India and Deutsche Gesellschaft für Internationale
                  Zusammenarbeit GmbH (GIZ) join hands to launch the flagship
                  Green Urban Mobility Innovation initiative in India
                </h3>

                <ul>
                  <li>
                    <span>*</span>Bosch and GIZ will implement sustainable,
                    inclusive, and smart mobility solutions across several
                    Indian cities
                  </li>
                  <li>
                    <span>*</span>Combines Bosch’s mobility innovation expertise
                    and strong local presence with GIZ’s public and private
                    sector network to set up a ‘Living Lab’ in Bengaluru
                  </li>
                  <li>
                    <span>*</span>The Green Urban Mobility Innovation project is
                    supported by develoPPP, German Federal Ministry for Economic
                    Cooperation and Development (BMZ) funding programme
                  </li>
                </ul>
                {/* <p>Combines Bosch’s mobility innovation expertise and strong local presence with GIZ’s public and private sector network to set up a ‘Living Lab’ in Bengaluru</p>
                                <p>	The Green Urban Mobility Innovation project is supported by develoPPP, German Federal Ministry for Economic Cooperation and Development (BMZ) funding programme </p> */}
              </div>
            </div>
            <div className="news-datadetails">
              <div>
                <p>
                  <span>Bengaluru, India – </span>Bosch Limited, one of the
                  leading suppliers of technology and services, in collaboration
                  with Deutsche Gesellschaft für Internationale Zusammenarbeit
                  (GIZ) GmbH jointly launched a Green Urban Mobility Innovation
                  initiative to implement sustainable, inclusive, and smart
                  mobility solutions across several Indian cities. The
                  initiative is under the aegis of the Green Urban Mobility
                  Partnership (GUMP) between the Ministry of Housing and Urban
                  Affairs (MoHUA) of the Government of India, and the German
                  Federal Ministry for Economic Cooperation and Development
                  (BMZ).
                </p>
              </div>
              <div>
                <p>
                  Bosch and GIZ aim to enhance the green urban mobility and
                  transportation system in India through an industry-led,
                  pre-competitive multi-stakeholder platform for innovation,
                  collaboration and prototyping. This flagship Indo-German
                  initiative will combine Bosch’s technical know-how of mobility
                  innovation and strong local presence with GIZ’s public and
                  private sector network to set up a ‘Living Lab’ in Bengaluru.
                  In addition to serving as a real-life testing environment for
                  green urban mobility innovations, the Living Labs will entail
                  a secretariat, governance structure, and an expert panel to
                  select and discuss innovations.{" "}
                </p>
              </div>
              <div>
                <p>
                  <span>
                    Philipp Knill, Head of Division, Policy issues of
                    cooperation with Asia / South Asia; German Federal Ministry
                    for Economic Cooperation and Development{" "}
                  </span>
                  stated, “Addressing the Sustainable Development Goals and
                  combating climate change requires collaboration between all
                  stakeholders involved. We see the Green Urban Mobility
                  Partnership Innovation initiative as a great example where
                  business meets development. By combining Bosch’s resources and
                  expertise with ours in the mobility sector, I am confident
                  that this initiative will have noticeable impact on a more
                  sustainable Indian transportation and mobility system.”
                </p>
              </div>
              <div>
                <p>
                  The Green Urban Mobility Innovation project is supported by
                  develoPPP, BMZ’s funding programme aimed at encouraging
                  sustainability initiatives by the private sector in developing
                  countries. As part of the cooperation, Bosch will deploy a
                  team from Bengaluru and Germany to conduct a needs assessment,
                  lead data and performance analysis of prototyping activities,
                  and contribute with its own network of mobility innovators to
                  expand the ‘Living Lab’ innovation community. GIZ public and
                  private partner network will help to create a neutral platform
                  for all stakeholders to cooperate in.
                </p>
              </div>
              <div>
                <p>
                  <span>
                    Soumitra Bhattacharya, President and MD, Bosch India Ltd.{" "}
                  </span>
                  said, “This is one of the major flagship partnerships that we
                  have entered into this year. Developing green urban mobility
                  solutions is a step closer to creating better sustainable
                  environment specially in countries like India with Air Quality
                  Index at alarming levels. The Green Urban Mobility Initiative
                  provides a great opportunity for deeper cooperation between
                  industry and government to bring about innovative, equitable
                  and sustainable change. At the Living Lab, we aim to encourage{" "}
                </p>
              </div>
              <div>
                <p>
                  <span>Dr. Julie Reviere, Country Director of GIZ India </span>
                  said, “Supporting public-private and multi-stakeholder
                  collaboration is a key principle of GIZ India. With this
                  initiative, we assist in establishing a multi-stakeholder
                  platform on Green Urban Mobility Innovation, hereby engaging
                  more than 100 stakeholders from the public sector, technology
                  companies, start-ups and other innovators. On behalf of the
                  German Federal Ministry for Economic Cooperation and
                  Development (BMZ), GIZ is already supporting the Ministry of
                  Housing and Urban Affairs (MoHUA) in planning and
                  implementation of a sustainable urban transport system in
                  selected Indian cities. The Living Lab will facilitate the
                  co-creation and adoption of innovative technology solutions
                  with a high social and environmental impact for Indian cities.
                  We are confident that our efforts will have a lasting impact
                  on India’s transportation and mobility ecosystem.”{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //events */}
      <section className="card-container">
        <div className="events large-container slick-cascade events-slick">
          <h2>Events</h2>
          <Slider {...teamSlider} className="slide-team">
            <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={event1} alt="banner-img" />
                </div>
                <div className="card-teamdata">
                  <h3>25</h3>
                  <p>MAR - 2022</p>
                  <div className="flexible">
                    <p>
                      An opportunity to interact with various private and public
                      sector stakeholders in this event.
                    </p>
                    <div>
                      <img src={clock} alt="banner-img" />
                      11:00 hrs - 16:15 hrs IST
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={logo} alt="banner-img" />
                </div>
                <div className="card-teamdata">
                  <div className="flexible">
                    <p>
                      The Urban Mobility Berlin India (UMBI) project aims to
                      connect German and Indian actors in addressing the
                      challenges of climate-friendly urban mobility in India.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={cycleday} alt="banner-img" />
                </div>
                <div className="card-teamdata">
                  <div className="flexible">
                    <p>
                    Cycle Day is a campaign aimed at making cycling a
                      conventional mode of travel, encouraging its usage for
                      short to mid-range commutes, local shopping trips, first
                      and last mile connectivity, as well as leisurely rides to
                      immerse in the serene greenery of our city.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={newsimage2} alt="banner-img" />
                </div>
                <div className="card-teamdata dumm">
                  <h3>Coming Soon</h3>
                </div>
              </div>
            </div> */}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default News;
