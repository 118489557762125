import Banner from "../../assets/images/png/Banner.png";
import map from "../../assets/images/png/Map1.png";
import "./Contact.scss";

const Contact = () => {
  return (
    <div className="contact">
      <section>
        <div className="banner-image">
          <img src={Banner} alt="banner-img" />
        </div>
      </section>
      {/* //contact */}
      <section className="card-container">
        <div className="thematic large-container">
          <h1>Contact Us</h1>
          <div className="contact-details">
            <div>
              <img src={map} alt="banner-img" />
            </div>
            <div className="contact-address">
            <div style={{fontWeight:"bold", color:"green"}}>
              GREEN URBAN MOBILITY INNOVATION LIVING LAB
              </div>
              <div>
                {/* Opposite SBI BTM Stage,<br></br>4 80 Feet Double Road,<br></br>
                2nd Block 4th Stage,<br></br>
                Vijaya Bank Layout, <br></br>
                Bilekahalli,<br></br>
                Bengaluru,<br></br>
                Karnataka 560027,<br></br>
                India.<br></br> */}
                Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH<br></br>
                802-803, Prestige Meridian-I,<br></br> 
                No. 29, M.G. Road, <br></br>
                Bengaluru-560 001,<br></br>
                India<br></br>
              </div>
              <div className="email">
                <div>Email Id:</div>
                <div>
                  manjunath.sekhar@giz.de<br></br>
                  puranjireddy.marutham@in.bosch.com 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
