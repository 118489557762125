import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { NavLink } from 'react-router-dom';
import menu from "../../assets/images/Icons/Hamburger_menu.png";
import close from "../../assets/images/Icons/Close.png";
import logo from "../../assets/images/Icons/newLogo.png";
import "./Header.scss";

const Header = () => {

    const [open, setOpen] = useState(false);

    const toggleHeader =  () => {
        setOpen(!open)
    }

    return(
        <AppBar 
            position="fixed"
            className="header-bar"
            classes={{
                root:"navbar"
            }}
        >
            <div className="logo">
                <NavLink to="/home">
                   <img src={logo} alt="menu"/>
                </NavLink>
            </div>
            <div className="mob-menu" onClick={toggleHeader}>
               <img src={menu} alt="menu"/>
            </div>
            {!open && (
                <div className="page-name">
                    <ul>        
                        <li><NavLink to="/home">Home</NavLink></li>
                        <li><NavLink to="/ourapproach">Our Approach</NavLink></li>
                        <li><NavLink to="/ouroffering">Our Offering</NavLink></li>
                        {/* <li><NavLink to="/projects">Projects</NavLink></li> */}
                        <li><NavLink to="/news&events">News & Events</NavLink></li>
                        <li><NavLink to="/transportationprinciples">Knowledge Product</NavLink></li>
                        <li><NavLink to="/contact">Contact</NavLink></li>
                    </ul>
                </div>
            )}
            {open && (
                <div className="mob-page-name">
                    <div className="mob-header-part">
                        <div className="logo">
                        <img src={logo} alt="menu"/>
                        </div>
                        <div onClick={toggleHeader}>
                          <img src={close} alt="close"/>
                        </div>
                    </div>
                    <ul>        
                        <li onClick={toggleHeader}><NavLink to="/home"><div>Home</div></NavLink></li>
                        <li onClick={toggleHeader}><NavLink to="/ourapproach"><div>Our Approach</div></NavLink></li>
                        <li onClick={toggleHeader}><NavLink to="/ouroffering"><div>Our Offering</div></NavLink></li>
                        {/* <li onClick={toggleHeader}><NavLink to="/projects"><div>Projects</div></NavLink></li> */}
                        <li onClick={toggleHeader}><NavLink to="/news&events"><div>News & Events</div></NavLink></li>
                        <li onClick={toggleHeader}><NavLink to="/transportationprinciples"><div>Knowledge Product</div></NavLink></li>
                        <li onClick={toggleHeader}><NavLink to="/contact"><div>Contact</div></NavLink></li>
                    </ul>
                </div>
            )}
        </AppBar>
    )
}

export default Header;
