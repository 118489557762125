// import React from 'react';
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
// import Grid from '@material-ui/core/Grid';
// import Button from "../../common/Button/Button";
import Banner from "../../assets/images/png/Banner.png";
import newsfeed from "../../assets/images/png/carousal_image2.png";
import newsfeed2 from "../../assets/images/png/carousal_image1.png";
import newsimage1 from "../../assets/images/png/news_image1.png";
//import newsimage2 from "../../assets/images/png/news_image2.png";
// import team from "../../assets/images/png/user_image1.png";
//import CardImg from "../../assets/images/png/card_img.png";
// import event1 from "../../assets/images/png/image1.png";
// import event2 from "../../assets/images/png/image2.png";
//import kamath from "../../assets/images/png/Kamath Panduranga.png";
// import CalebRonaldMunigetty from "../../assets/images/png/Caleb Ronald Munigetty.png";
// import Dinakara from "../../assets/images/png/Dinakara.png";
// //import BhimannaKattimani from "../../assets/images/png/Bhimanna Kattimani.png";
// import KaushikRattawa from "../../assets/images/png/Kaushik Rattawa.png";
// import Juergen from "../../assets/images/png/Juergen.png";
// import VivekKotikalapudi from "../../assets/images/png/Vivek Kotikalapudi.png";
// import Aravindh from "../../assets/images/png/Aravind.png";
// import manjunath from "../../assets/images/png/Manjunath Sekhar.png";
// import prasanth from "../../assets/images/png/prashanth_A.png";
// import laghu from "../../assets/images/png/Laghu Parashar.png";
// import puranji from "../../assets/images/png/Puranji Reddy.png";
//import prashanth from "../../assets/images/png/Prashanth T P.png"

import Icon1 from "../../assets/images/Icons/Icon1.png";
import Icon2 from "../../assets/images/Icons/Icon2.png";
import Icon3 from "../../assets/images/Icons/Icon3.png";
import Icon4 from "../../assets/images/Icons/Icon4.png";
import Icon5 from "../../assets/images/Icons/Icon5.png";
import Icon6 from "../../assets/images/Icons/Icon6.png";
import Icon7 from "../../assets/images/Icons/Icon7.png";
import Icon8 from "../../assets/images/Icons/Icon8.png";
import Icon9 from "../../assets/images/Icons/Icon9.png";
import Icon10 from "../../assets/images/Icons/Icon10.png";

import logo from "../../assets/images/png/GUMILLlogo.png";

import accessibility from "../../assets/images/png/Street Elements Audits1.jpeg";
import cycleDay from "../../assets/images/png/CycleDay1.jpg";
import training from "../../assets/images/png/InauguralSession.jpg";

import SamplePrevArrow from "../../assets/images/Icons/Left_angle.png";
import SampleNextArrow from "../../assets/images/Icons/Right_angle.png";
import "./Home.scss";

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  var teamSlider = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: (
      <div>
        {" "}
        <img src={SampleNextArrow} alt="banner-img" />{" "}
      </div>
    ),
    prevArrow: (
      <div>
        {" "}
        <img src={SamplePrevArrow} alt="banner-img" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 771,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="Home">
      {/* Banner section */}
      <section className=" home-banner-image">
        <div>
          <img src={Banner} alt="banner-img" />
        </div>
      </section>
      {/* newsfeed section */}
      <section className="card-container bg-darkGrey">
        <div className="newsfeed large-container">
          <Slider {...settings} className="slide-news">
            <div className="newsfeed-card">
              <h1>Newsfeed</h1>
              <div>
                <div className="newsfeed-image">
                  <img src={newsfeed2} alt="banner-img" />
                </div>
                <div className="newsfeed-data">
                  <h1>Newsfeed</h1>
                  <p className="web">
                    Public Launch Event on March 25<sup>th</sup> 2022
                  </p>
                  <p className="mob">
                    Public Launch Event on March 25<sup>th</sup> 2022
                  </p>
                  <p className="web">
                    An opportunity to interact with various private and <br />{" "}
                    public sector stakeholders in this event.
                  </p>
                  <p className="mob">
                    An opportunity to interact with various private and public
                    sector stakeholders in this event.
                  </p>
                </div>
              </div>
            </div>
            <div className="newsfeed-card">
              <h1>Newsfeed</h1>
              <div>
                <div className="newsfeed-image">
                  <img src={newsfeed} alt="banner-img" />
                </div>
                <div className="newsfeed-data">
                  <h1>Newsfeed</h1>
                  <p className="web">
                    Kick off Event on November 15<sup>th</sup> 2021
                  </p>
                  <p className="mob">
                    Kick off Event on November 15<sup>th</sup> 2021
                  </p>
                  <p className="web">
                    Bosch India and Deutsche Gesellschaft für Internationale{" "}
                    <br /> Zusammenarbeit GmbH (GIZ) join hands to launch the{" "}
                    <br /> flagship Green Urban Mobility Innovation initiative
                    in India .
                  </p>
                  <p className="mob">
                    Bosch India and Deutsche Gesellschaft für Internationale
                    Zusammenarbeit GmbH (GIZ) join hands to launch the flagship
                    Green Urban Mobility Innovation initiative in India .
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* about us */}
      <section className="card-container">
        <div className="about-us large-container">
          <h1>Our Vision</h1>
          <div className="founder-tag">
            Establish the Living Lab as a platform and methodology to be
            replicated across Indian cities to foster collaboration around Green
            Urban Mobility Innovations that leave no one behind.
          </div>
          <h1>Our Mission</h1>
          <div className="founder-tag founder-bottom">
            We aim to enhance green urban mobility and the transportation
            systems in India by creating an open platform for innovation,
            collaboration, and prototyping, bringing public and private
            stakeholders together under the roof of a Living Lab (LL).
          </div>
          <h1>About Us</h1>
          <p>
            Under the aegis of the Green Urban Mobility Partnership (GUMP)
            between Germany and India, supported by the develoPPP program of
            Federal Ministry for Economic Cooperation and Development (BMZ),
            Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH
            and BOSCH India Ltd. have entered into a three year cooperation
            project on “Green Urban Mobility Innovation” and are setting up a
            “Living Lab” in Bengaluru to demonstrate how technologies can be
            used to bring sustainable and inclusive mobility solutions in India.
          </p>
        </div>
      </section>
      {/* /thematic/ */}
      <section className="card-container bg-darkWhite">
        <div className="thematic large-container">
          <h1>Thematic Areas Of Focus</h1>
          <p>
            The Living Lab will work on opportunity areas that contribute to
            building a green urban mobility system that leaves no one behind,
            starting from Bangalore but open to test the approach to innovations
            would be shortlisted based on a pre-defined scoring mechanism on the
            basis of criteria e.g., impact on air quality, gender equality,
            social inclusion, scalability, viability, ease of implementation,
            etc.
          </p>
          <div className="thematic-details">
            <div className="thematic-data">
              <div>
                <img src={Icon1} alt="banner-img" />
              </div>
              <p>Public Transport</p>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon2} alt="banner-img" />
              </div>
              <p>Mobility As A Service</p>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon3} alt="banner-img" />
              </div>
              <p>Sustainable Urban Mobility Plan</p>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon4} alt="banner-img" />
              </div>
              <p>Cycling</p>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon5} alt="banner-img" />
              </div>
              <div>Micro - Mobility</div>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon6} alt="banner-img" />
              </div>
              <p>Electric Road Vehicles</p>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon7} alt="banner-img" />
              </div>
              <p>Energy Efficiency</p>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon8} alt="banner-img" />
              </div>
              <p>Freight Transport</p>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon9} alt="banner-img" />
              </div>
              <p>Walking</p>
            </div>
            <div className="thematic-data">
              <div>
                <img src={Icon10} alt="banner-img" />
              </div>
              <p>Reducing GHG Emissions</p>
            </div>
          </div>
        </div>
      </section>

      {/* /news/ */}
      <section className="card-container bg-darkGrey">
        <div className="news large-container slick-cascade">
          <h2>News</h2>
          <Slider {...teamSlider} className="slide-team">
            <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={newsimage1} alt="banner-img" />
                </div>
                <div className="card-teamdata">
                  <h3>
                    Bosch India and Deutsche Gesellschaft für Internationale
                    Zusammenarbeit GmbH (GIZ) join hands to launch the flagship
                    Green Urban Mobility Innovation initiative in India{" "}
                    <NavLink to="/news&events">Know More</NavLink>
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={logo} alt="banner-img" />
                </div>
                <div className="card-teamdata">
                  {/* <p>Coming Soon</p> */}
                  <h3>
                    Urban Mobility Berlin India (UMBI) Innovation Challenge.
                    <p style={{ fontSize: "1rem", fontWeight: "100" }}>
                      10 startups, 7 days, pushing forward urban mobility in
                      India. <br></br>Are you up for the Urban Mobility Berlin
                      India (UMBI) innovation challenge?
                    </p>
                    <a
                      href="https://enpact.org/ecosystem-bridges/umbi/"
                      target={"blank"}
                    >
                      Know More
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={accessibility} alt="banner-img" />
                </div>
                <div className="card-teamdata">
                  <h3>
                    Accessibility Audits for Persons with Disabilities.
                    <p style={{ fontSize: "1rem", fontWeight: "100" }}>
                      GUMILL has partnered with RampMyCity (RMC), a social
                      start-up providing simple infrastructural physical
                      accessibility solutions and adequate human training in
                      public and private sectors for the inclusion of Persons
                      with Disabilities (PwDs) and Elderly population of India.
                    </p>
                    <NavLink to="/accessibilityaudit">Know More</NavLink>
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={cycleDay} alt="banner-img" />
                </div>
                <div className="card-teamdata">
                  <h3>
                    Cycle Day- Pedal Power
                    <p style={{ fontSize: "1rem", fontWeight: "100" }}>
                      Cycle Day is a campaign aimed at making cycling a
                      conventional mode of travel, encouraging its usage for
                      short to mid-range commutes, local shopping trips, first
                      and last mile connectivity, as well as leisurely rides to
                      immerse in the serene greenery of our city.
                    </p>
                    <NavLink to="/cycleday">Know More</NavLink>
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                <div>
                  <img src={training} alt="banner-img" />
                </div>
                <div className="card-teamdata">
                  <h3>
                    Capacity Building for development of cycling infrastructure
                    in urban environment
                    <p style={{ fontSize: "1rem", fontWeight: "100" }}>
                      GIZ India and the Directorate of Urban Land Transport,
                      Government of Karnataka organised a training programme on
                      ‘Cycling Infrastructure for Urban Environments’ in
                      association with the Dutch Cycling Embassy (DCE) between
                      February 13 and February 17, 2023.
                    </p>
                    <NavLink to="/training">Know More</NavLink>
                  </h3>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* //our team */}
      <section className="card-container">
        <div className="our-team slick-cascade">
          <h1>Our Team</h1>
          <div className="team-cardouter">
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={prasanth} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Prashanth A</h3>
                  <p>Chief Business Officer, 
                  Mobility Platform and Services (MPS), Bosch IND </p>
                </div>
              </div>
            </div>
            {/* <div className="card-outer">
              <div className="card-inner">
                <div className="user-img">
                  <img src={Juergen} alt="banner-img" />
                </div> 
                <div className="card-teamdata">
                  <h3>Juergen Baumann</h3>
                  <p>Project Head, GIZ IND</p>
                </div>
              </div>
            </div> */}
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={kamath} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Kamath Panduranga</h3>
                  <p>Head of Engineering, Bosch IND</p>
                </div>
              </div>
            </div>
            {/* <div className="card-outer">
              <div className="card-inner">
                 <div className="user-img">
                  <img src={laghu} alt="banner-img" />
                </div> 
                <div className="card-teamdata">
                  <h3>Laghu Parashar</h3>
                  <p>Deputy Project Head, GIZ IND</p>
                </div>
              </div>
            </div> */}
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={CalebRonaldMunigetty} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Dr.Caleb Ronald Munigety</h3>
                  <p>
                    Research Lead, 
                    {/* Intelligent Transportation Systems,  */}
                    Bosch IND
                  </p>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={manjunath} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Manjunath Sekhar</h3>
                  <p>Project Manager, GIZ IND</p>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={Dinakara} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Dinakara G S</h3>
                  <p>Engineering Manager, Bosch IND</p>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={puranji} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Puranji Reddy M</h3>
                  <p>Project Manager, Bosch IND</p>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={VivekKotikalapudi} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Vivekanand Kotikalapudi</h3>
                  <p>Technical Expert, GIZ IND</p>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={Aravindh} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Aravindakshan Ramanan</h3>
                  <p>Technical Expert, GIZ IND</p>
                </div>
              </div>
            </div>
            <div className="card-outer">
              <div className="card-inner">
                {/* <div className="user-img">
                  <img src={KaushikRattawa} alt="banner-img" />
                </div> */}
                <div className="card-teamdata">
                  <h3>Shyam Sundar Rampalli</h3>
                  <p>Assistant Manager, Bosch IND</p>
                </div>
              </div>
            </div>
            {/* <div className="card-outer">
              <div className="card-inner">
               <div className="user-img">
                  <img src={prashanth} alt="banner-img" />
                </div> 
                <div className="card-teamdata">
                  <h3>Pradeep T P</h3>
                  <p>Deputy General Manager, Bosch IND</p>
                </div>
              </div>
            </div> */}
            <div className="card-outer">
              <div className="card-inner">
               {/* <div className="user-img">
                  <img src={prashanth} alt="banner-img" />
                </div>  */}
                <div className="card-teamdata">
                  <h3>Neetu Joseph</h3>
                  <p>Technical Expert, GIZ IND</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
