import Banner from "../../assets/images/png/Banner.png";
// import CardImg from "../../assets/images/png/card_img.png";
import parking from "../../assets/images/png/ParkingManagement.png";
import data from "../../assets/images/png/10DataPrinciples.png";
import years from "../../assets/images/png/2023_GIZ_SUT_Poster_10years.jpg";
import women from "../../assets/images/png/5-Principles-to-Empower-Women-in-Transport-Poster.jpg";
import "./TransportationPrinciples.scss";
import "../News/News.scss";

const TransportationPrinciples = () => {

  const downloadImage = (name) => {
    const anchor = document.createElement('a');
    anchor.href = name;
    switch(name){
      case years: anchor.download = '10-Principles-for-Sustainable-Urban-Transport.jpg';
                    break;
      case women: anchor.download = '5-Principles-for-Women-and-Transport.jpg';
                    break;
      case data: anchor.download = '10-Data-Principles-to-Transform-Public-Transport.jpg';
                    break;
      case parking: anchor.download = 'Parking-Management.jpg';
                    break;
      default: anchor.download = '10-Principles.jpg';
                    break;
    }
    anchor.click();
  };

  const myStyle = {
    backgroundColor: "#008CBA",
  border: "none",
  color: "white",
  padding: "12px 16px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "12px",
  cursor: "pointer",
  float: "right"
  }


  return (
    <div className="Events">
      <section>
        <div className="banner-image">
          <img src={Banner} alt="banner-img" />
        </div>
      </section>
      {/* /news/ */}
      <section className="card-container bg-darkGrey">
        <div className="news large-container slick-cascade">
          <h2>Information on Sustainable Transportation Principles</h2>
          <div className="news-details">
            <div>
              <div>
                <br></br>
                <img src={years} alt="banner-img" style={{padding:"10px 0 0 0"}}/>
                <button style={myStyle} onClick={() => downloadImage(years)}>Download the above Image</button>
                <br></br>
              </div>
              <div>
                <br></br>
                <img src={women} alt="banner-img" style={{padding:"30px 0 0 0"}}/>
                <button style={myStyle} onClick={() => downloadImage(women)}>Download the above Image</button>
                <br></br>
              </div>
              <div>
                <br></br>
                <img src={data} alt="banner-img" style={{padding:"30px 0 0 0"}}/>
                <button style={myStyle} onClick={() => downloadImage(data)}>Download the above Image</button>
                <br></br>
              </div>
              <div className="parent-img">
                <br></br>
                <img src={parking} alt="banner-img" style={{padding:"30px 0 0 0"}}/>
                <div className="wrap-btn">
                <button style={myStyle} onClick={() => downloadImage(parking)}>Download the above Image</button>
                </div>
                <br></br>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </div>
  );
};

export default TransportationPrinciples;
