import Banner from "../../assets/images/png/Banner.png";
// import CardImg from "../../assets/images/png/card_img.png";
import cycleday from "../../assets/images/png/CycleDay5.jpg";

import "../News/News.scss";

const CycleDay = () => {
  return (
    <div className="Events">
      <section>
        <div className="banner-image">
          <img src={Banner} alt="banner-img" />
        </div>
      </section>
      {/* /news/ */}
      <section className="card-container bg-darkGrey">
        <div className="news large-container slick-cascade">
          <h2>Events</h2>
          <div className="news-details">
            <div className="news-images">
              <div>
                <br></br>
                <br></br>
                <img src={cycleday} alt="banner-img" />
              </div>
              <div>
                <h3>Cycle Day- Pedal Power </h3>

                <ul>
                  <li>
                    <span>*</span>In the bustling urban landscape, our streets
                    have long been dominated by cars, relegating pedestrians and
                    cyclists to the side-lines. Embracing cycling offers a
                    plethora of benefits that extend beyond individual commuters
                    to impact the entire community and the environment.
                    Integrating cycling and walking into urban transport
                    planning offers a multitude of benefits, ranging from
                    environmental conservation and congestion reduction to
                    improved public health and community well-being.
                    Transforming streets into pedestrian-friendly spaces can
                    revitalize local businesses. Pedestrian and cyclist foot
                    traffic increase visibility and patronage, supporting local
                    economies and creating vibrant commercial districts. This
                    ideology forms the core of “Cycle day” conducted by Green
                    Urban Mobility Innovation-Living Lab, Bengaluru. Cycle Day
                    is a campaign aimed at making cycling a conventional mode of
                    travel, encouraging its usage for short to mid-range
                    commutes, local shopping trips, first and last mile
                    connectivity, as well as leisurely rides to immerse in the
                    serene greenery of our city.
                  </li>
                  {/* <li>
                    <span>*</span>The objective of Disability Inclusion is to
                    guarantee that individuals with disabilities have fair and
                    impartial access to services that are available to those
                    without disabilities. Accessibility then, is an important
                    pillar of disability inclusion.
                  </li>
                  <li>
                    <span>*</span>Government of India has initiated a
                    nation-wide campaign ‘Accessible India Campaign (Sugamya
                    Bharat Abhiyan)’ launched by Department of Empowerment of
                    Persons with Disabilities (DEPwD) of Ministry of Social
                    Justice & Empowerment to provide universal accessibility to
                    persons with disabilities. The campaign aims at providing
                    equal opportunity to persons with disabilities to
                    participate in all aspects of life and live independently by
                    developing accessible physical environment, transportation
                    system and Information & communication ecosystem.
                  </li> */}
                </ul>
                {/* <p>Combines Bosch’s mobility innovation expertise and strong local presence with GIZ’s public and private sector network to set up a ‘Living Lab’ in Bengaluru</p>
                                <p>	The Green Urban Mobility Innovation project is supported by develoPPP, German Federal Ministry for Economic Cooperation and Development (BMZ) funding programme </p> */}
              </div>
            </div>
            <div className="news-datadetails">
              <div>
                <p>
                  <span>A transformative movement is underway – </span>
                  Reclaiming our urban streets to prioritize pedestrians and
                  cyclists. By doing so, we can create vibrant, people-centric
                  spaces that foster community, improve health, and enhance the
                  overall urban experience. Cycle Day events highlight the
                  positive impact cycling can have on our environment and
                  encourage individuals to adopt this eco-conscious mode of
                  transport. These events aim to showcase how cycling can
                  improve cardiovascular health, boost mental well-being, and
                  contribute to overall fitness. Cycle Day events are more than
                  just fun-filled gatherings; they serve a higher purpose –
                  promoting cycling as a sustainable and eco-friendly mode of
                  transportation.
                </p>
              </div>
              <div>
                <p>
                  <span>The community is the essence of the movement – </span>
                  Cycle Day events create a sense of community and togetherness.
                  As we come together to pedal for a common cause, we build
                  connections with like-minded individuals, fostering a strong
                  community bond. Cycling promotes social interactions, bringing
                  people from diverse backgrounds together. The Malleswaram
                  Swabhimana Initiative (MSI) is a residential association of
                  Malleswaram and has been an active supporter of the Cycle day.
                  MSI is a distinguished example of what change a small
                  initiative and communication can bring about in the
                  neighbourhood. With two of the three cycle days already being
                  conducted, we saw a tremendous response and engagement from
                  the residents including people from all age groups and gender.
                  This gave us a nudge for incorporating the concept of Slow
                  Streets which aligns with the propaganda of Cycle day.
                  Residents took part in identifying the busiest and most unsafe
                  streets, which could be taken up for slow street project.
                </p>
              </div>
              <div>
                <p>
                  <span>Streets user to be age and gender inclusive – </span>
                  Cycling is a mode of transport that is accessible to people of
                  all ages and abilities. Cycle Day events showcase how cycling
                  infrastructure can be designed to cater to everyone's needs,
                  promoting inclusivity and equal access to transportation.
                  Inclusive footpath usage encourages vulnerable road users,
                  such as elderly individuals or people with mobility
                  challenges, to feel more comfortable and safe using footpaths.
                  This promotes equal access to urban spaces for everyone.
                </p>
              </div>
              <div>
                <p>
                  <span>Advocating safe streets – </span>
                  Safety is paramount when promoting cycling. Cycle Day events
                  provide an opportunity to raise awareness about safe cycling
                  practices, road etiquette, and the importance of proper
                  cycling infrastructure. We aim to create a safer environment
                  for cyclists especially the younger population who want to
                  cycle to school and colleges but unfortunately are not able to
                  due to heavy traffic and speeding vehicles. On cycle days,
                  cyclists are also advised to ride at reduced speeds and
                  exercise caution when sharing footpaths with pedestrians. This
                  promotes safe and considerate behaviour, making footpaths
                  safer for all users.
                </p>
              </div>
              <div>
                <p>
                  In conclusion, promoting cycling in transport planning is a
                  multifaceted approach to tackle congestion. It not only
                  addresses the immediate traffic-related issues but also
                  improves public health, mitigates environmental impact, and
                  fosters a sense of community. By investing in cycling
                  infrastructure and encouraging a cycling culture, cities can
                  pave the way for a more sustainable, efficient, and
                  congestion-free future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CycleDay;
