import Banner from "../../assets/images/png/Banner.png";
// import CardImg from "../../assets/images/png/card_img.png";
import training from "../../assets/images/png/CycleRide.jpg";

import "../News/News.scss";

const Training = () => {
  return (
    <div className="Events">
      <section>
        <div className="banner-image">
          <img src={Banner} alt="banner-img" />
        </div>
      </section>
      {/* /news/ */}
      <section className="card-container bg-darkGrey">
        <div className="news large-container slick-cascade">
          <h2>News</h2>
          <div className="news-details">
            <div className="news-images">
              <div>
                <br></br>
                <br></br>
                <img src={training} alt="banner-img" />
              </div>
              <div>
                <h3>
                  Capacity Building for development of cycling infrastructure in
                  urban environment
                </h3>

                <ul>
                  <li>
                    <span>*</span>In COP 26, India had pledged to achieve Net
                    Zero emissions by 2070. Studies have shown transport
                    contributes to around 13 percent of the total carbon dioxide
                    emission in India. Decarbonisation of transport sector is
                    key for India to achieve its climate goals. Active mobility
                    is seen as a key enabling factor supporting this goal.
                  </li>
                  <li>
                    <span>*</span>The infrastructure for cycling is at a nascent
                    stage in India. To learn from the best global practices of
                    cycling infrastructure and adapt them to local needs, GIZ
                    India and the Directorate of Urban Land Transport,
                    Government of Karnataka organised a training programme on
                    ‘Cycling Infrastructure for Urban Environments’ in
                    association with the Dutch Cycling Embassy (DCE) between
                    February 13 and February 17, 2023. With the highest bicycle
                    use worldwide, the Netherlands is known for its bicycle
                    policy and infrastructure, and DCE is a pioneer in sharing
                    its successful learnings globally.
                  </li>
                  {/* <li>
                    <span>*</span>The 5-day residential training was attended by
                    40+ participants from Urban Local Bodies and city
                    administration executives of four states and included
                    classroom sessions and site visits exploring discourses
                    around cycling infrastructure design, combination of bicycle
                    and public transport, traffic education, bicycle stimulation
                    among other topics. The government agencies pledged to
                    implement key learnings from the training program into the
                    cycling infrastructure design in their respective cities.
                  </li> */}
                </ul>
                {/* <p>Combines Bosch’s mobility innovation expertise and strong local presence with GIZ’s public and private sector network to set up a ‘Living Lab’ in Bengaluru</p>
                                <p>	The Green Urban Mobility Innovation project is supported by develoPPP, German Federal Ministry for Economic Cooperation and Development (BMZ) funding programme </p> */}
              </div>
            </div>
            <div className="news-datadetails">
              <div>
                <p>
                The 5-day residential training was attended by
                    40+ participants from Urban Local Bodies and city
                    administration executives of four states and included
                    classroom sessions and site visits exploring discourses
                    around cycling infrastructure design, combination of bicycle
                    and public transport, traffic education, bicycle stimulation
                    among other topics. The government agencies pledged to
                    implement key learnings from the training program into the
                    cycling infrastructure design in their respective cities.
                </p>
              </div>
              <div>
                <p>
                  In line with this initiative, the Green Urban Mobility
                  Innovation Living Lab (GUMILL) is currently undertaking an
                  accessibility audit of the Malleswaram Testbed area. This is
                  to evaluate the degree of accessibility for differently abled,
                  elderly and pedestrians in general, and come up with
                  implementable recommendations that would improve the
                  accessibility in the Testbed. The Green Urban Mobility
                  Innovation Living Lab will work with the local authorities to
                  carry out the required interventions.
                </p>
              </div>
              <div>
                <p>
                  GUMILL has partnered with RampMyCity (RMC), a social start-up
                  providing simple infrastructural physical accessibility
                  solutions and adequate human training in public and private
                  sectors for the inclusion of Persons with Disabilities (PwDs)
                  and Elderly population of India. The audit covers
                  Environmental Barriers: including physical infrastructural and
                  communication/information barriers such as absence of ramps,
                  lifts; space for wheelchair manoeuvring; inadequate light and
                  signage and different information formats for persons with
                  visual, hearing and intellectual disabilities. The audit
                  covers all major streets, government and private buildings
                  including schools, colleges, banks and hospitals. It also
                  looks into accessibility at all the major bus stops and the
                  metro station within the Malleswaram Testbed area.
                </p>
              </div>
              <div>
                <p>
                  GUMILL also proposes to conduct a one-day training workshop as
                  part of this initiative for the engineers of the municipal
                  corporation, which would include classroom sessions and site
                  visits to understand accessibility challenges through the
                  point of view of Persons with Disabilities.
                </p>
              </div>
              <div>
                <p>
                  GUMILL aims to enhance the quality of life of the citizens in
                  the Malleswaram testbed by demonstrating sustainable solutions
                  to the citizens and to the government partners. This
                  Accessibility Audit, in collaboration with innovators, in a
                  testbed, is a showcase of the Living Lab methodology to help
                  the city government observe the solutions closely and realise
                  the benefits to scale them up to the rest of the city.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Training;
