import React from 'react';
import AppRoutes from "./routes";
import './App.scss';

const App = () => {
  return (
    <div className="App">
        <AppRoutes/>
    </div>
  );
}

export default App;

