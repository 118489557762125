import {
    Outlet 
  } from 'react-router-dom';
import Footer_img_1 from "../assets/images/png/Green_urban_logo.png";
import logo4 from "../assets/images/png/develop_logo.png";
import Footer_img_3 from "../assets/images/png/German_logo.png";
import Footer_img_4 from "../assets/images/png/GIZ_logo.png";
import Bosch from "../assets/images/png/Bosch_logo.png";
import Header from "../common/Header/Header";
import "./Layout.scss";

const Layout = () => {
    return(
        <div className="Layout">
            <Header />
            <div className="children">
                <div className="container">
                  <Outlet />
                </div>
                <div className="Footer">
                   <div className="footer-images">
                       <div>
                           <div>
                              <img src={Footer_img_3} alt="Footer_img_1" />
                           </div>
                       </div>
                       <div>
                           <div>
                              <img src={Footer_img_4} alt="Footer_img_1" />
                           </div>
                       </div>
                       <div>
                           <div>
                              <img src={Footer_img_1} alt="Footer_img_1" />
                           </div>
                       </div>
                       <div>
                           <div>
                              <img src={logo4} alt="Footer_img_1" />
                           </div>
                       </div>
                       <div>
                           <div>
                              <img src={Bosch} alt="Footer_img_1" />
                           </div>
                       </div>
                    </div>
                    <div className="footer-rights">
                        <div className="web-rights">
                            <p>© 2022</p>
                            <p>GUMLL</p>
                            <p>ALL Rights Reserved</p>
                        </div>
                        {/* <div className="web-privacy">
                            <p>Help & Support</p>
                            <p>Legal</p>
                            <p>Data Privacy</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout;