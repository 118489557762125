import Banner from "../../assets/images/png/Banner.png";
// import CardImg from "../../assets/images/png/card_img.png";
import accessbus from "../../assets/images/png/Accessibility Audits at Bus Stop.jpeg";

import "../News/News.scss";

const AccessibilityAudit = () => {
  return (
    <div className="Events">
      <section>
        <div className="banner-image">
          <img src={Banner} alt="banner-img" />
        </div>
      </section>
      {/* /news/ */}
      <section className="card-container bg-darkGrey">
        <div className="news large-container slick-cascade">
          <h2>News</h2>
          <div className="news-details">
            <div className="news-images">
              <div>
                <br></br>
                <img src={accessbus} alt="banner-img" />
              </div>
              <div>
                <h3>Accessibility Audits for Persons with Disabilities</h3>

                <ul>
                  <li>
                    <span>*</span>Individuals with disabilities possess equal
                    entitlements as any other individual to engage in societal
                    activities, including but not limited to pursuing education,
                    establishing relationships with family and friends,
                    obtaining employment, and actively participating in social,
                    political, and cultural affairs and occasions. Nevertheless,
                    in practical terms, individuals with disabilities frequently
                    encounter barriers that prevent them from exercising these
                    rights and actively participating in life, leading to their
                    exclusion.
                  </li>
                  <li>
                    <span>*</span>The objective of Disability Inclusion is to
                    guarantee that individuals with disabilities have fair and
                    impartial access to services that are available to those
                    without disabilities. Accessibility then, is an important
                    pillar of disability inclusion.
                  </li>
                  <li>
                    <span>*</span>Government of India has initiated a
                    nation-wide campaign ‘Accessible India Campaign (Sugamya
                    Bharat Abhiyan)’ launched by Department of Empowerment of
                    Persons with Disabilities (DEPwD) of Ministry of Social
                    Justice & Empowerment to provide universal accessibility to
                    persons with disabilities. The campaign aims at providing
                    equal opportunity to persons with disabilities to
                    participate in all aspects of life and live independently by
                    developing accessible physical environment, transportation
                    system and Information & communication ecosystem.
                  </li>
                </ul>
                {/* <p>Combines Bosch’s mobility innovation expertise and strong local presence with GIZ’s public and private sector network to set up a ‘Living Lab’ in Bengaluru</p>
                                <p>	The Green Urban Mobility Innovation project is supported by develoPPP, German Federal Ministry for Economic Cooperation and Development (BMZ) funding programme </p> */}
              </div>
            </div>
            <div className="news-datadetails">
              <div>
                <p>
                  In line with this initiative, the Green Urban Mobility
                  Innovation Living Lab (GUMILL) is currently undertaking an
                  accessibility audit of the Malleswaram Testbed area. This is
                  to evaluate the degree of accessibility for differently abled,
                  elderly and pedestrians in general, and come up with
                  implementable recommendations that would improve the
                  accessibility in the Testbed. The Green Urban Mobility
                  Innovation Living Lab will work with the local authorities to
                  carry out the required interventions.
                </p>
              </div>
              <div>
                <p>
                  GUMILL has partnered with RampMyCity (RMC), a social start-up
                  providing simple infrastructural physical accessibility
                  solutions and adequate human training in public and private
                  sectors for the inclusion of Persons with Disabilities (PwDs)
                  and Elderly population of India. The audit covers
                  Environmental Barriers: including physical infrastructural and
                  communication/information barriers such as absence of ramps,
                  lifts; space for wheelchair manoeuvring; inadequate light and
                  signage and different information formats for persons with
                  visual, hearing and intellectual disabilities. The audit
                  covers all major streets, government and private buildings
                  including schools, colleges, banks and hospitals. It also
                  looks into accessibility at all the major bus stops and the
                  metro station within the Malleswaram Testbed area.
                </p>
              </div>
              <div>
                <p>
                  GUMILL also proposes to conduct a one-day training workshop as
                  part of this initiative for the engineers of the municipal
                  corporation, which would include classroom sessions and site
                  visits to understand accessibility challenges through the
                  point of view of Persons with Disabilities.
                </p>
              </div>
              <div>
                <p>
                  GUMILL aims to enhance the quality of life of the citizens in
                  the Malleswaram testbed by demonstrating sustainable solutions
                  to the citizens and to the government partners. This
                  Accessibility Audit, in collaboration with innovators, in a
                  testbed, is a showcase of the Living Lab methodology to help
                  the city government observe the solutions closely and realise
                  the benefits to scale them up to the rest of the city.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AccessibilityAudit;
