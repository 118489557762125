import {
    BrowserRouter as Router,
    Routes,
    Navigate ,
    Route
} from "react-router-dom";
import Layout from "./Layout";
import HomePage from "../containers/HomePage";
import ApproachPage from "../containers/ApproachPage";
import ContactPage from "../containers/ContactPage";
import OfferingPage from "../containers/OfferingPage";
// import ProjectsPage from "../containers/ProjectsPage";
import NewsPage from "../containers/NewsPage";
import ScrollToTop from "../routes/scrollTop";
import AccessibilityAudit from "../components/NewsAndEvents/AccessibilityAudit";
import CycleDay from "../components/NewsAndEvents/CycleDay";
import Training from "../components/NewsAndEvents/Training";
import TransportationPrinciples from "../components/NewsAndEvents/TransportationPrinciples";

const AppRoutes = () => {

    return(
        <Router 
        > 
            <Routes>
                <Route element={<ScrollToTop/>}>
                    <Route element={<Layout/>}>
                        <Route path="/" element={<Navigate replace to="/home" />} />
                        <Route path="/home" element={<HomePage />}/>
                        <Route path="/ourapproach" element={<ApproachPage />}/>
                        <Route path="/ouroffering" element={<OfferingPage />}/>
                        {/* <Route path="/projects" element={<ProjectsPage />}/> */}
                        <Route path="/news&events" element={<NewsPage />}/>
                        <Route path="/contact" element={<ContactPage />}/>
                        <Route path="/accessibilityaudit" element={<AccessibilityAudit />} />
                        <Route path="/cycleday" element={<CycleDay />} />
                        <Route path="/training" element={<Training />} />
                        <Route path="/transportationprinciples" element={<TransportationPrinciples />} />
                    </Route>
                </Route>
            </Routes>

        </Router>
    )
}

export default AppRoutes;
