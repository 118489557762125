import Banner from "../../assets/images/png/Banner.png";
import corporate from "../../assets/images/png/Corporate.png";
import government from "../../assets/images/png/Government_Agencies.png";
import academic from "../../assets/images/png/Academic_Institutes.png";
import civic from "../../assets/images/png/Civic_Society.png";
import Investors from "../../assets/images/png/Investors.png";
import start from "../../assets/images/png/Start-ups.png";
import profits from "../../assets/images/png/Non- Profits.png";
// import logo8 from "../../assets/images/png/logo-8.png";
import Approach_Step_1 from "../../assets/images/png/Approach_Step_1.png";
import Approach_Step_2 from "../../assets/images/png/Approach_Step_2.png";
import Approach_Step_3 from "../../assets/images/png/Approach_Step_3.png";
import Approach_Step_4 from "../../assets/images/png/Approach_Step_4.png";

import Approach_Step_5 from "../../assets/images/png/Approach_Step_5.png";
import "./Approach.scss";

const Approach = () => {
    return(
        <div className="ourApproach">
            <section>
                <div className="banner-image">
                    <img src={Banner} alt="banner-img"/>
                </div>
           </section>
           {/* //our approach */}
           <section className="card-container approach-bg">
                <div className="approach large-container">
                    <h2>Our Approach</h2>
                    <p>
                        True to the Living Lab, we will pursue the following in letter and in spirit.
                    </p>
                    <div className="grid-outer-iamge">
                        <div>
                          <img src={Approach_Step_1} alt="stack" />
                        </div>
                        <div>
                          <img src={Approach_Step_2} alt="stack" />
                        </div>
                        <div>
                          <img src={Approach_Step_3} alt="stack" />
                        </div>
                        <div>
                          <img src={Approach_Step_4} alt="stack" />
                        </div>
                        <div>
                          <img src={Approach_Step_5} alt="stack" />
                        </div>
                    </div>
                </div>
            </section>
            {/* //stake holders */}
            <section className="card-container">
                <div className="stack-holder large-container">
                    <h2>Stakeholders</h2>
                    <div className="stackholder-outer">
                        <div className="stackholder-inner">
                          <img src={corporate} alt="stack" />
                        </div>
                        <div className="stackholder-inner">
                          <img src={government} alt="stack" />
                        </div>
                        <div className="stackholder-inner">
                          <img src={academic} alt="stack" />
                        </div>
                        <div className="stackholder-inner">
                          <img src={civic} alt="stack" />
                        </div>
                        <div className="stackholder-inner">
                          <img src={Investors} alt="stack" />
                        </div>
                        <div className="stackholder-inner">
                          <img src={start} alt="stack" />
                        </div>
                        <div className="stackholder-inner">
                          <img src={profits} alt="stack" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Approach;