import Slider from "react-slick";
// import team from "../../assets/images/png/user_image1.png";
// import CardImg from "../../assets/images/png/card_img.png";
import Banner from "../../assets/images/png/Banner.png";
import service1 from "../../assets/images/png/Service_1.png";
import service2 from "../../assets/images/png/Service_2.png";
import service3 from "../../assets/images/png/Service_3.png";
import service4 from "../../assets/images/png/Service_4.png";
import service5 from "../../assets/images/png/Service_5.png";
import service6 from "../../assets/images/png/Service_6.png";
import service7 from "../../assets/images/png/Service_7.png";
import service8 from "../../assets/images/png/Service_8.png";
import serving from "../../assets/images/png/offering_image.png";
import newsimage2 from "../../assets/images/png/news_image2.png";

// import event1 from "../../assets/images/png/image1.png";
// import event2 from "../../assets/images/png/image_4.png";
// import event3 from "../../assets/images/png/image_3.png";
import SamplePrevArrow from "../../assets/images/Icons/Left_angle.png";
import SampleNextArrow from "../../assets/images/Icons/Right_angle.png";
import "./Offering.scss";

const Offering = () => {

    var teamSlider = {
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 4,
        nextArrow: <div> <img src={SampleNextArrow} alt="banner-img"/> </div>,
        prevArrow: <div> <img src={SamplePrevArrow} alt="banner-img"/></div>,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              }
            },
            {
                breakpoint: 771,
                settings: {
                  arrows: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1,
                }
              },
         ]
    }

    return(
        <div className="Offerings">
            <section>
                <div className="banner-image">
                    <img src={Banner} alt="banner-img"/>
                </div>
           </section>
           {/* //ourOfferings */}
           <section className="card-container">
                <div className="offering-container large-container">
                    <h2>Our Offerings and Services</h2>
                    <div className="service-outer">
                        <div className="service-top">
                            <div className="service-card">
                                <div>
                                    <img src={service1} alt="sevice-img" />
                                </div>
                                <div>
                                    <p>Real - life environment to run experiments</p>
                                </div>
                            </div>
                            <div className="service-card">
                                <div>
                                    <img src={service8} alt="sevice-img" />
                                </div>
                                <div>
                                    <p>Co - creation platform</p>
                                </div>
                            </div>
                        </div>
                        <div className="service-middle">
                            <div className="service-card-left">
                                <div className="service-card">
                                    <div>
                                        <img src={service2} alt="sevice-img" />
                                    </div>
                                    <div>
                                        <p>Mobility Data</p>
                                    </div>
                                </div>
                                <div className="service-card">
                                    <div>
                                        <img src={service3} alt="sevice-img" />
                                    </div>
                                    <div>
                                        <p>Instrumented vehicle(s)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="service-card-middle">
                               <img src={serving} alt="sevice-img" />
                            </div>
                            <div className="service-card-right">
                                <div className="service-card">
                                    <div>
                                        <img src={service7} alt="sevice-img" />
                                    </div>
                                    <div>
                                        <p>Active end - user involvement</p>
                                    </div>
                                </div>
                                <div className="service-card">
                                    <div>
                                        <img src={service6} alt="sevice-img" />
                                    </div>
                                    <div>
                                        <p>Multi - method approach</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-bottom">
                            <div className="service-card">
                                <div>
                                    <img src={service4} alt="sevice-img" />
                                </div>
                                <div>
                                    <p>Digital - twin for virtual experimentation, verification and validation</p>
                                </div>
                            </div>
                            <div className="service-card">
                                <div>
                                    <img src={service5} alt="sevice-img" />
                                </div>
                                <div>
                                    <p>Access to mobility experts</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
           {/* //knowledgeresource */}
           <section className="card-container bg-darkGrey">
              <div className="knowledge-container large-container slick-cascade">
                <h2>Knowledge Resources</h2>
                <Slider {...teamSlider} className="slide-team">
                    <div className="card-outer">
                        <div className="card-inner">
                        <div className="user-img">
                                <img src={newsimage2} alt="banner-img"/>
                            </div>
                            <div className="card-teamdata">
                                <h3>Project report on air quality monitoring systems</h3>
                                <p>Coming soon</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-outer">
                        <div className="card-inner">
                        <div className="user-img">
                                <img src={newsimage2} alt="banner-img"/>
                            </div>
                            <div className="card-teamdata">
                                <h3>Project report on traffic flow models</h3>
                                <p>Coming soon</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-outer">
                        <div className="card-inner">
                        <div className="user-img">
                                <img src={newsimage2} alt="banner-img"/>
                            </div>
                            <div className="card-teamdata">
                                <h3>Project report on air quality monitoring systems</h3>
                                <p>Coming soon</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-outer">
                        <div className="card-inner">
                        <div className="user-img">
                                <img src={newsimage2} alt="banner-img"/>
                            </div>
                            <div className="card-teamdata">
                                <h3>Project report on traffic flow models</h3>
                                <p>Coming soon</p>
                            </div>
                        </div>
                    </div>
                </Slider>
              </div>
           </section>
        </div>
    )
}

export default Offering;